import { RouteRecordRaw } from "vue-router";

export const userRoutes: RouteRecordRaw[] = [
	{
		path: "/notifications",
		name: "user-notifications",
		props: true,
		component: () => import("@/views/Notifications.vue"),
		meta: {
			pageName: "User Notifications",
			friendlyPath: "/user/notifications",
			fullWidth: true,
			boxed: true,
			showDetailsHeader: true,
			showBackButton: true,
			header: "Notifications",
			actionButton: "Mark All Read",
			actionButtonEmits: "notifications:mark-all-read",
			hideBottomNav: true,
			actionButtonManagerOnly: false
		},
	},
	{
		path: "/schedule",
		name: "user-schedule",
		props: true,
		component: () => import("@/components/User/CalendarTab.vue"),
		meta: {
			pageName: "User Schedule",
			friendlyPath: "/user/schedule",
		},
	},
	{
		path: "/",
		name: "user-overview",
		props: true,
		component: () => import("@/components/User/OverviewTab.vue"),
		meta: {
			pageName: "User Overview",
			friendlyPath: "/user",
		},
	},
	{
		path: "/communications",
		name: "user-communications--home",
		props: true,
		component: () => import("@/components/User/CommunicationTab.vue"),
		meta: {
			pageName: "User Communications",
			friendlyPath: "/user/communications",
		},
		children: [
			{
				path: "",
				name: "user-communications",
				component: () => import("@/components/User/CommunicationsTableCard.vue"),
				meta: {
					pageName: "User Communications",
					friendlyPath: "/user/communications",
					boxed: true,
					fullWidth: true,
				},
			},
			{
				path: "/:communicationId",
				name: "user-view-communication",
				props: true,
				component: () => import("@/components/User/CommunicationsTableCard.vue"),
				meta: {
					pageName: "User View Communication",
					friendlyPath: "/user/communication/**",
					boxed: true,
					fullWidth: true,
				},
			},
			{
				path: "/reports",
				name: "user-reports",
				component: () => import("@/components/Entity/Reports/ReportsTable.vue"),
				meta: {
					pageName: "User Reports",
					friendlyPath: "/user/communications/reports",
					boxed: true,
					fullWidth: true,
				},
			},
		],
	},

	{
		path: "/memberships",
		name: "user-memberships",
		props: true,
		component: () => import("@/components/User/EntitiesTab.vue"),
		meta: {
			pageName: "User Memberships",
			friendlyPath: "/user/memberships",
		},
	},
	{
		path: "/user-onboarding",
		name: "user-onboarding",
		props: true,
		component: () => import("@/components/Onboarding/UserOnboarding.vue"),
		meta: {
			pageName: "User Onboarding",
			friendlyPath: "/user/onboarding",
		},
	},
];
