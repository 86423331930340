export enum PrimaryIndustry {
    Other = 0,
    Union = 1,
    NonUnion = 2,
    Education = 3,
    Dance = 4,
    Choral = 5
}

export const getPrimaryIndustry = (status: PrimaryIndustry) => {
    switch (status) {
        case PrimaryIndustry.Other:
            return { name: 'Other', class: 'warning' }
        case PrimaryIndustry.Union:
            return { name: 'Union', class: 'warning' }
        case PrimaryIndustry.NonUnion:
            return { name: 'Non-Union', class: 'warning' }
        case PrimaryIndustry.Education:
            return { name: 'Education', class: 'warning' }
        case PrimaryIndustry.Dance:
            return { name: 'Dance', class: 'warning' }
        case PrimaryIndustry.Choral:
            return { name: 'Choral', class: 'warning' }
        default:
            return { name: 'Unknown', class: 'warning' }
    }
}
