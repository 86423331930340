export enum InitialPersona {
    Individual = 1,
    Organization = 2
}
export const getInitialPersona = (status: InitialPersona) => {
    switch (status) {
        case InitialPersona.Individual:
            return { name: 'Individual', class: 'warning' }
        case InitialPersona.Organization:
            return { name: 'Organization', class: 'warning' }
        default:
            return { name: 'Unknown', class: 'warning' }
    }
}
