import {ProductionMembership} from "@/models/productions/ProductionMembership";
import {OrganizationMembership} from "@/models/organizations/OrganizationMembership";
import {ProductionService} from "@/services/ProductionService";
import {OrganizationService} from "@/services/OrganizationService";
import {MembershipRole} from "@/models/enums/Role";
import {MembershipStatus} from "@/models/enums/MembershipStatus";

export const permissions = {
    namespaced: true,
    state: () => ({
        productions: [] as Array<ProductionMembership>,
        organizations: [] as Array<OrganizationMembership>,
        orgFetchComplete: false,
        prodFetchComplete: false
    }),
    //dispatch('permissions/fetch')
    actions: {
        fetchProductionMemberships: function (context: any) {
            ProductionService.list().then(response => {
                context.commit('setProductionMemberships', response.data)
            })
        },
        fetchOrganizationMemberships: function (context: any) {
            OrganizationService.list().then(response => {
                context.commit('setOrganizationMemberships', response.data)
            })
        }
    },
    //commit('permissions/set')
    mutations: {
        setProductionMemberships: function (
            state: any,
            memberships: Array<ProductionMembership>
        ) {
            state.productions = memberships;
            state.prodFetchComplete = true
        },
        setOrganizationMemberships: function (
            state: any,
            memberships: Array<OrganizationMembership>
        ) {
            state.organizations = memberships;
            state.orgFetchComplete = true
        }
    },
    //getters['permissions/isAdmin']
    getters: {
        fetchComplete: (state) => {
            return state.prodFetchComplete && state.orgFetchComplete
        },
        hasAccessToEntities: (state) => {
            return state.productions.filter(p => p.status == MembershipStatus.Active).length > 0 || state.organizations.filter(o => o.status == MembershipStatus.Active).length > 0;
        },
        lookupProductionId: (state) => (productionId) => {
            try {
                return state.productions.find(production => production.productionId === productionId);
            } catch (ex) {
                return null;
            }
        },
        lookupOrganizationId: (state) => (organizationId) => {
            //console.log('lookupOrganizationId', organizationId)
            try {
                return state.organizations.find(organization => organization.organizationId === organizationId);
            } catch (ex) {
                return null;
            }
        },
        canAccess: (state) => (entityId) => {
            const production = state.organizations.find(p => p.productionId === entityId);
            if (production) {
                return true;
            }
            const organization = state.organizations.find(p => p.organizationId === entityId);
            return !!organization;
        },
        canManage: (state) => (entityId) => {
            const production = state.productions.find(p => p.productionId === entityId);
            if (production && (production.role !== MembershipRole.Member)) {
                return true;
            }
            const organization = state.organizations.find(p => p.organizationId === entityId);
            if (organization && (organization.role !== MembershipRole.Member)) {
                return true;
            }
            return false;
        },
        isAdmin: (state) => (entityId) => {
            const production = state.productions.find(p => p.productionId === entityId);
            if (production && production.role === MembershipRole.Administrator || production.role === MembershipRole.Owner) {
                return true;
            }
            const organization = state.organizations.find(p => p.organizationId === entityId);
            return !!(organization && organization.role === MembershipRole.Administrator || production.role === MembershipRole.Owner);
        },
        canManageAny: (state) => {
            let result = state.productions.filter(p => p.role !== MembershipRole.Member).length > 0;
            if (!result) {
                result = state.productions.filter(p => p.role !== MembershipRole.Member).length > 0;
            }
            return result;
        },
        currentEntity: (state, getters, rootState) => {
            const route = rootState.application.route
            if (route.name.indexOf('organization') > -1) {
                return route.params['organizationId'] as string
            } else {
                return route.params['productionId'] as string
            }
        },
        currentEntityName: (state, getters, rootState) => {
            const route = rootState.application.route
            try {
                if (route.name.indexOf('organization') > -1) {
                    const entityId = route.params['organizationId'] as string
                    const organization = getters.lookupOrganizationId(entityId)
                    return organization.name
                } else {
                    const entityId = route.params['productionId'] as string
                    const production = getters.lookupProductionId(entityId)
                    return production.name
                }
            }catch (err){
                return '';
            }
        }
    }
}
