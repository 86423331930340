import { __awaiter } from "tslib";
import { defineComponent, onMounted } from "vue";
import { useApplication } from "@/store/pinia/applicationStore";
import ApplicationWrapper from "@/ApplicationWrapper.vue";
import auth0 from "@/auth";
import { useMockAuth } from "@/modules/authWrapper";
import { isPlatform } from "@ionic/vue";
import { useRoute } from "vue-router";
import { StatusBar, Style } from '@capacitor/status-bar';
const isCypressTest = !!localStorage.getItem("auth0Cypress");
const auth = isCypressTest ? useMockAuth() : auth0;
export default defineComponent({
  components: {
    ApplicationWrapper
  },
  setup() {
    const applicationStore = useApplication();
    const route = useRoute();
    onMounted(() => __awaiter(this, void 0, void 0, function* () {
      window.initializeKT();
      if (isPlatform('capacitor')) {
        yield StatusBar.setStyle({
          style: Style.Dark
        });
        yield StatusBar.setBackgroundColor({
          color: '1E1E2D'
        });
      }
    }));
    return {
      applicationStore,
      route
    };
  },
  methods: {
    signOut() {
      auth.logout({
        logoutParams: {
          returnTo: isPlatform("capacitor") ? process.env.VUE_APP_CAP_ENDSESSION : process.env.VUE_APP_AUTH0_APPLOGOUT
        }
      });
    }
  },
  computed: {
    currentRoute() {
      return this.route.fullPath;
    },
    isStart() {
      return this.route.name === "start";
    },
    isSignUp() {
      return this.route.name === "sign-up";
    },
    isLoading() {
      return auth.isLoading.value;
    },
    isAuthenticated() {
      return auth.isAuthenticated.value;
    },
    appIsLoaded() {
      return this.applicationStore.isLoaded;
    },
    isCapacitor() {
      return isPlatform("capacitor");
    }
  }
});