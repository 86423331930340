import {User} from "@/models/user/User";
import {Invitation} from "@/models/user/Invitation";
import {ProductionMembership} from "@/models/productions/ProductionMembership";
import {OrganizationMembership} from "@/models/organizations/OrganizationMembership";
import {UserService} from "@/services/UserService";
import router from "@/router";
import {OrganizationService} from "@/services/OrganizationService";
import {ProductionService} from "@/services/ProductionService";
import {WebAnalytics} from "@/modules/webAnalytics";
import moment from "moment";
import {getGenderIdentity} from "@/models/enums/GenderIdentity";
import {getInitialPersona} from "@/models/enums/InitialPersona";
import {getUserType} from "@/models/enums/UserType";
import {getPrimaryIndustry} from "@/models/enums/PrimaryIndustry";
import {NotificationItem} from "@/models/notifications/Notification";

export const userState = {
    namespaced: true,
    state: () => ({
        user: {firstName: '', lastName: ''} as User,
        invitations: {} as Array<Invitation>,
        productions: [] as Array<ProductionMembership>,
        organizations: [] as Array<OrganizationMembership>,
        notifications: [] as Array<NotificationItem>
    }),
    //dispatch('user/fetch')
    actions: {
        fetchUser: function (context: any) {
            //console.log('fetchUser')
            UserService.fetch().then(response => {
                if (!response.data.userExists) {
                    router.push({name: 'sign-up'})
                    context.commit('application/setLoadingState', true, {root: true})
                } else {
                    context.commit('setUser', response.data)
                    context.commit('application/setAuthenticatedState', true, {root: true})
                    context.commit('application/setLoadingState', true, {root: true})
                    context.dispatch("fetchInvitations")
                    context.dispatch("fetchProductionMemberships")
                    context.dispatch("fetchOrganizationMemberships")
                    context.dispatch("fetchNotifications")
                    context.dispatch("permissions/fetchProductionMemberships", null, {root: true})
                    context.dispatch("permissions/fetchOrganizationMemberships", null, {root: true})
                }
            });
        },
        fetchInvitations: function (context: any) {
            UserService.fetchInvitations().then(response => {
                context.commit('setInvitations', response.data)
            })
        },
        fetchProductionMemberships: function (context: any) {
            ProductionService.list().then(response => {
                context.commit('setProductionMemberships', response.data)
            })
        },
        fetchOrganizationMemberships: function (context: any) {
            OrganizationService.list().then(response => {
                context.commit('setOrganizationMemberships', response.data)
            })
        },
        fetchNotifications: function (context: any) {
            UserService.fetchNotifications().then(response => {
                context.commit('setNotifications', response.data)
            })
            setInterval(function () {
                UserService.fetchNotifications().then(response => {
                    context.commit('setNotifications', response.data)
                })
            }, 30000)
        },
        setNotificationAsRead: function (context: any, notificationId: string) {
            UserService.markReadNotifications([notificationId]).then(() => {
                context.commit('setNotificationAsRead', notificationId)
            })
        }
    },
    //commit('user/set')
    mutations: {
        setUser: function (state: any, user: User) {
            state.user = user
            WebAnalytics.identify({
                id: user.accountId,
                email: user.emailAddresses.filter(x => x.isPrimary)[0].email,
                firstName: user.firstName,
                lastName: user.lastName,
                name: `${user.firstName} ${user.lastName}`,
                title: user.title,
                avatar: user.avatar,
                $avatar: user.avatar,
                StageCode: user.stageCode,
                createdAt: moment(user.createdOn).format(),
                pronouns: user.pronouns,
                gender: getGenderIdentity(user.genderIdentity).name,
                birthday: moment(user.birthdate).format(),
                age: moment().diff(moment(user.birthdate), 'years', false),
                industry: getPrimaryIndustry(user.industry).name,
                initialPersona: getInitialPersona(user.initialPersona).name,
                userType: getUserType(user.userType).name
            });
        },
        setInvitations: function (state: any, invitations: Array<Invitation>) {
            state.invitations = invitations;
        },
        setProductionMemberships: function (
            state: any,
            memberships: Array<ProductionMembership>
        ) {
            state.productions = memberships;
        },
        setOrganizationMemberships: function (
            state: any,
            memberships: Array<OrganizationMembership>
        ) {
            state.organizations = memberships;
        },
        setNotifications: function (state: any, notifications: Array<Notification>) {
            state.notifications = notifications
        },
        setNotificationAsRead: function (state: any, notificationId: string) {
            state.notifications.forEach(notification => {
                if(notification.notificationId === notificationId){
                    notification.read = true
                }
            })
        }
    },
    //getters['user/get']
    getters: {},
};
