export enum UserType {
    Other = 0,
    IndividualPerformance = 1,
    IndividualCrew = 2,
    IndividualProductionTeam = 3,
    Student = 4,
    Educator = 5,
    Producer = 6
}

export const getUserType = (status: UserType) => {
    switch (status) {
        case UserType.Other:
            return { name: 'Other', class: 'warning' }
        case UserType.IndividualPerformance:
            return { name: 'Individual (Performance)', class: 'warning' }
        case UserType.IndividualCrew:
            return { name: 'Individual (Crew)', class: 'warning' }
        case UserType.IndividualProductionTeam:
            return { name: 'Individual (Production Team)', class: 'warning' }
        case UserType.Student:
            return { name: 'Student', class: 'warning' }
        case UserType.Educator:
            return { name: 'Educator', class: 'warning' }
        case UserType.Producer:
            return { name: 'Producer', class: 'warning' }
        default:
            return { name: 'Unknown', class: 'warning' }
    }
}
