export enum MembershipStatus {
    Active,
    PendingUser,
    PendingEntity,
    UserRevoked,
    EntityRevoked
}

export const getMembershipStatus = (status: MembershipStatus) => {
    switch (status) {
        case MembershipStatus.Active:
            return { name: 'Active', class: 'success' }
        case MembershipStatus.EntityRevoked:
            return { name: 'Entity Revoked', class: 'danger' }
        case MembershipStatus.PendingEntity:
            return { name: 'Pending Entity', class: 'success' }
        case MembershipStatus.PendingUser:
            return { name: 'Pending User', class: 'warning' }
        case MembershipStatus.UserRevoked:
            return { name: 'User Revoked', class: 'danger' }
        default:
            return { name: 'To Be Assigned', class: 'info' }
    }
}
