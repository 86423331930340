export enum MembershipRole {
    Owner = 0,
    Administrator,
    Manager,
    Member
}

export const getMembershipRole = (role: MembershipRole) => {
    switch (role) {
        case MembershipRole.Owner:
            return {name: 'Owner', class: 'warning'}
        case MembershipRole.Administrator:
            return {name: 'Administrator', class: 'success'}
        case MembershipRole.Manager:
            return {name: 'Manager', class: 'info'}
        case MembershipRole.Member:
            return {name: 'Member', class: 'primary'}
        default:
            return {name: 'Unknown Role', class: 'danger'}
    }
}
