const Style = {
    base: [
        "color: #fff",
        "background-color: #1d3557",
        "padding: 2px 4px",
        "border-radius: 2px",
        "font-weight: bold",
        "text-transform: uppercase"
    ],
    debug: [],
    info: [
        "background-color: #4361ee"
    ],
    warn: [
        "color: black",
        "background-color: #f9c74f"
    ],
    error: [
        "background-color: #d00000"
    ],
    fatal: [
        "background-color: #6a040f"
    ],
}

export const getLogStyle = (logStyleType: string) => {
    let style = Style.base.join(';') + ';';
    style += Style[logStyleType].join(';');
    return style;
}
