import { createStore } from 'vuex'
import {ui} from "@/store/ui";
import {userState} from "@/store/user";
import {permissions} from "@/store/permissions";
import {communicationModalStore} from "@/store/modals/communication.modal.store";
import {applicationState} from "@/store/application";

/*
export interface State {
  ui: {
    activeGlobalTab
  }
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {},
  modules: {
    oidcStore: vuexOidcCreateStoreModule(oidcSettings, {namespaced: false}),
    ui
  }
})

// define your own `useStore` composition function
export function useStore () {
  return baseUseStore(key)
}
*/


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ui: ui,
    user: userState,
    permissions: permissions,
    application: applicationState,
    communicationModal: communicationModalStore
  },
  //plugins: [vuexPlugin]
})

