import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
import _imports_0 from '@/assets/logo-white.png';
const _hoisted_1 = {
  key: 0,
  class: "m-auto"
};
const _hoisted_2 = {
  class: "standby-box add-flex standby-box--container"
};
const _hoisted_3 = {
  class: "standby-box--container"
};
const _hoisted_4 = {
  key: 0,
  class: "d-flex flex-column mx-4 mt-4"
};
const _hoisted_5 = {
  key: 1,
  class: "d-flex flex-column mx-4 mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ApplicationWrapper = _resolveComponent("ApplicationWrapper");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [!_ctx.appIsLoaded && !_ctx.isStart && !_ctx.isSignUp ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[6] || (_cache[6] = _createStaticVNode("<img class=\"img-fluid mb-5\" style=\"max-width:35%;\" src=\"" + _imports_0 + "\" alt=\"\"><div class=\"spinner mb-5\"><div class=\"spinner-item\"></div><div class=\"spinner-item\"></div><div class=\"spinner-item\"></div><div class=\"spinner-item\"></div><div class=\"spinner-item\"></div></div>", 2)), !_ctx.isCapacitor ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "d-flex gap-2 align-items-baseline justify-content-center"
  }, [_createElementVNode("div", {
    class: "fs-2 mb-3"
  }, "Download the app on the App Store!"), _createElementVNode("div", {
    class: "d-none badge badge-light-warning align-self-start"
  }, "Beta")], -1)), _cache[3] || (_cache[3] = _createElementVNode("h2", {
    class: "text-gray-800 fs-4"
  }, [_createTextVNode("Available on both the App Store"), _createElementVNode("small", null, "™"), _createTextVNode(" and Google Play"), _createElementVNode("small", null, "™")], -1)), _createElementVNode("div", {
    class: "mt-8 fw-bold text-gray-700 cursor-pointer",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.signOut && _ctx.signOut(...args))
  }, " Having trouble loading? ")])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_cache[4] || (_cache[4] = _createElementVNode("div", {
    class: "d-flex gap-2 align-items-baseline justify-content-center"
  }, [_createElementVNode("div", {
    class: "fs-2 mb-3"
  }, "A Refreshed Look is Coming Soon"), _createElementVNode("div", {
    class: "d-none badge badge-light-warning align-self-start"
  }, "Beta")], -1)), _cache[5] || (_cache[5] = _createElementVNode("h2", {
    class: "text-gray-800 fs-4"
  }, "Easier scheduling, enhanced report designer and so much more!", -1)), _createElementVNode("div", {
    class: "mt-8 fw-bold text-gray-700 cursor-pointer",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.signOut && _ctx.signOut(...args))
  }, " Having trouble loading? ")]))])])])) : _createCommentVNode("", true), !_ctx.isLoading && _ctx.isAuthenticated && !_ctx.isStart && !_ctx.isSignUp ? (_openBlock(), _createBlock(_component_ApplicationWrapper, {
    key: 1
  })) : _createCommentVNode("", true), _ctx.isSignUp ? (_openBlock(), _createBlock(_component_router_view, {
    key: 2,
    name: "signup"
  })) : _createCommentVNode("", true), _ctx.isStart ? (_openBlock(), _createBlock(_component_router_view, {
    key: 3,
    name: "start"
  })) : _createCommentVNode("", true)], 64);
}