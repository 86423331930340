import CommunicationModal from "@/components/modals/CommunicationModal.vue";
import {bus} from "@/modules/eventBus";

export const communicationModalStore = {
    namespaced: true,
    state: () => ({
        entity: {
            communicationId: '',
            name: '',
            content: '',
            type: 1
        }
    }),
    //dispatch('communicationModal/login')
    actions: {
        invokeAdd({ commit }){
            commit('initialize', {
                communicationId: '',
                name: '',
                content: '',
                type: 1
            })
            bus.emit('modals/communication:open')
        },
        invokeUpdate({commit}, communicationId: string){
            //fetch from api
            commit('initialize', {
                communicationId: communicationId,
                name: 'Hello there',
                content: 'I am a test',
                type: 1
            })
            bus.emit('modals/communication:open')
        }
    },
    //commit('communicationModal/login')
    mutations: {
        initialize(state: any, entity: any){
            state.entity = entity
        }
    },
    //getters['communicationModal/isAdmin']
    getters: { }
}
