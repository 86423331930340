import { createAuth0 } from '@auth0/auth0-vue';
import {isPlatform} from "@ionic/vue";

const auth0 = createAuth0({
    domain: (isPlatform("capacitor") ? process.env.VUE_APP_AUTH0_DOMAIN : process.env.VUE_APP_AUTH0_DOMAIN_BRANDED) || '',
    clientId: process.env.VUE_APP_AUTH0_CLIENTID || '',
    authorizationParams: {
        redirect_uri: isPlatform("capacitor")
            ? process.env.VUE_APP_AUTH0_CAPCALLBACK
            : window.location.origin,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    },
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
});

export default auth0;
