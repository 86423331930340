export const ui = {
    namespaced: true,
    state: () => ({
        activeGlobalTab: 'Home',
        activeEntityTab: 'Dashboard',
        isEntityMode: false,
        isUserSettingsMode: false,
        activeUserSettingTab: 'General',
        entityName: '',
        entity: {
          people: {
              tab: 'members'
          }
        },
        people: {
            tab: 'members' //members, groups, requests
        },
        user: {
            communications: {
                tab: ''
            },
            scheduling: {
                tab: ''
            },
            memberships: {
                tab: 'memberships' //memberships, requests
            }
        }
    }),
    //dispatch('account/login')
    actions: {

    },
    //commit('account/login')
    mutations: {
        changeGlobalTab(state: any, tabName: string){
            state.activeGlobalTab = tabName
            state.isUserSettingsMode = tabName === 'Settings'
        },
        changeEntityTab(state: any, tabName: string){
            state.activeEntityTab = tabName
        },
        changeUserSettingTab(state: any, tabName: string){
            state.activeUserSettingTab = tabName
        },
        changeEntityMode(state: any, isEntityMode: boolean){
            state.isEntityMode = isEntityMode
        },
        updateEntityName(state: any, name: string){
            state.entityName = name
        },
        changePeopleTab(state: any, tab: string){
            state.people.tab = tab
        },
        changeUserMembershipsTab(state: any, tab: string){
            state.user.memberships.tab = tab
        }
    },
    //getters['account/isAdmin']
    getters: {
        peopleCurrentTab: (state) => {
            return state.people.tab
        },
        userMembershipsCurrentTab: (state) => {
            return state.user.memberships.tab
        }
    }
}
