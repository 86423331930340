import {registerPlugin} from '@capacitor/core';

export interface AnalyticsPlugin {
	track(options: { event: string; properties?: { [key: string]: any } }): Promise<void>;

	identify(options: { userId: string; traits?: { [key: string]: any } }): Promise<void>;

	page(options: { name: string; properties?: { [key: string]: any } }): Promise<void>;

	reset(): Promise<void>;
}

export const NativeAnalytics = registerPlugin<AnalyticsPlugin>('NativeAnalytics');
