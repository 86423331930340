export enum GenderIdentity {
   "Other",
   "NonBinary",
   "Man",
   "Woman"
}

export const getGenderIdentity = (status: GenderIdentity) => {
   switch (status) {
      case GenderIdentity.Woman:
         return { name: 'Woman', class: 'warning' }
      case GenderIdentity.NonBinary:
         return { name: 'Non-Binary', class: 'warning' }
      case GenderIdentity.Man:
         return { name: 'Man', class: 'warning' }
      default:
         return { name: 'Unknown', class: 'warning' }
   }
}
